import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid2,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// import { ChainId } from '@pancakeswap/chains';
import {
  ChainId,
  CurrencyAmount,
  Fetcher,
  Percent,
  Route,
  Token,
  Trade,
  TradeType,
} from '@pancakeswap/sdk';
import { useImmer } from 'use-immer';
import {
  Hex,
  createPublicClient,
  createWalletClient,
  erc20Abi,
  fallback,
  formatEther,
  getContract,
  http,
  isAddress,
  isHex,
  parseEther,
} from 'viem';
import { privateKeyToAccount } from 'viem/accounts';
import { bsc } from 'viem/chains';
// import { CurrencyAmount, Token, TradeType } from '@pancakeswap/swap-sdk-core';
import pairAbi from '@/abis/pair';
import routerAbi from '@/abis/router';
import NumericFormatCustom from '../common/NumericFormatCustom';

export default function FormPropsTextFields() {
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [onProcessing, setOnProcessing] = useState(false);
  const [pairAddress] = useState<Hex>('0x80709fd0e67e62cccceab855d66a552ca75cb30b');
  const [usdtAddress] = useState<Hex>('0x55d398326f99059fF775485246999027B3197955');
  const [tokenAddress] = useState<Hex>('0x90c5be7e6d427913e8dc8c31c758445efe4c0cfd');
  const [routerAddress] = useState<Hex>('0x10ED43C718714eb63d5aA57B78B54704E256024E');

  const matches = useMediaQuery('(max-width:603px)');

  const { t } = useTranslation();

  const handleClickShowPrivateKey = () => setShowPrivateKey(show => !show);

  const handlePwdMouseEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [tradingForm, setTradingForm] = useImmer<{ [key: string]: string }>({
    privateKey:
      process.env.NODE_ENV === 'production'
        ? ''
        : '0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80',
    rpc:
      process.env.NODE_ENV === 'production' ? 'https://rpc.ankr.com/bsc' : 'http://localhost:8545',
    slippage: '0.5',
    deadline: '5',
    orderType: '1',
    maxNumberOfTrades: '1',
    orderSize: '',
    tradeInterval: '0',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTradingForm(draft => {
      draft[event.target.name] = event.target.value;
    });
  };

  const { isValidPrivateKey, account } = useMemo(() => {
    if (!isHex(tradingForm.privateKey)) return { isValidPrivateKey: false, account: undefined };

    try {
      const account = privateKeyToAccount(tradingForm.privateKey);
      const isValidAddr = isAddress(account.address);
      return { isValidPrivateKey: isValidAddr, account: isValidAddr ? account.address : undefined };
    } catch (e) {
      return { isValidPrivateKey: false, account: undefined };
    }
  }, [tradingForm.privateKey]);

  const isRunnable = useMemo(() => {
    if (!tradingForm || !isValidPrivateKey) return false;

    return Number(tradingForm.orderSize) > 0;
  }, [tradingForm, isValidPrivateKey]);

  const publicClient = useMemo(
    () =>
      createPublicClient({
        chain: bsc,
        transport: fallback([http(tradingForm.rpc), http()]),
      }),
    [tradingForm.rpc],
  );

  const walletClient = useMemo(() => {
    if (!isValidPrivateKey || !account) return undefined;

    return createWalletClient({
      account,
      chain: bsc,
      transport: fallback([http(tradingForm.rpc), http()]),
    });
  }, [isValidPrivateKey, account, tradingForm.rpc]);

  // const runSellHandler = async () => {
  //   console.log('Trading form:', tradingForm);

  //   try {
  //     if (!account || !walletClient) return;

  //     setOnProcessing(true);
  //     const erc20 = getContract({
  //       address: tokenAddress,
  //       abi: erc20Abi,
  //       client: { public: publicClient, wallet: walletClient },
  //     });

  //     const router = getContract({
  //       address: routerAddress,
  //       abi: routerAbi,
  //       client: { public: publicClient, wallet: walletClient },
  //     });
  //     const universal = getContract({
  //       address: '0x1A0A18AC4BECDDbd6389559687d1A73d8927E416',
  //       abi: universalAbi,
  //       client: { public: publicClient, wallet: walletClient },
  //     });

  //     const pair = getContract({
  //       address: pairAddress,
  //       abi: pairAbi,
  //       client: { public: publicClient, wallet: walletClient },
  //     });

  //     const permit2 = getContract({
  //       address: '0x31c2F6fcFf4F8759b3Bd5Bf0e1084A055615c768',
  //       abi: permit2Abi,
  //       client: { public: publicClient, wallet: walletClient },
  //     });

  //     const approvalAmount = parseEther(
  //       `${Number(tradingForm.maxNumberOfTrades) * Number(tradingForm.orderSize)}`,
  //     );
  //     console.log('approve');
  //     const amount = Number(approvalAmount) + (Number(approvalAmount) * 25) / 10000;
  //     // await erc20.write.approve(['0x31c2F6fcFf4F8759b3Bd5Bf0e1084A055615c768', BigInt(amount)]);
  //     // await erc20.write.approve(['0x1A0A18AC4BECDDbd6389559687d1A73d8927E416', BigInt(amount)]);
  //     // await erc20.write.approve([pairAddress, BigInt(amount)]);
  //     await erc20.write.approve([routerAddress, BigInt(amount)]);

  //     for (let i = 0; i < Number(tradingForm.maxNumberOfTrades); i++) {
  //       const totalSupply = await pair.read.totalSupply(); // LP token
  //       if (totalSupply === 0n) {
  //         console.log(`Pool is empty, trading is cancelled`);
  //         continue;
  //       }

  //       const tokenIn = tokenAddress;
  //       const tokenOut = usdtAddress;

  //       // `${Number(tradingForm.orderSize) - (Number(tradingForm.orderSize) * 25) / 10000}`,
  //       const amountIn = parseEther(tradingForm.orderSize);

  //       const amounts = await router.read.getAmountsOut([amountIn, [tokenIn, tokenOut]]);
  //       console.log('amounts:::', amounts);
  //       const amountOutMin =
  //         amounts[1] - BigInt(Number(amounts[1]) * Number(tradingForm.slippage)) / 100n;
  //       console.log('amountOutMin:::', amountOutMin);

  //       const [, , nonce] = await permit2.read.allowance([account, tokenIn, routerAddress]);
  //       console.log('nonce:::', nonce);

  //       const expiration = BigInt(Date.now() + 1000 * 60 * Number(tradingForm.deadline || 5)); //BigInt(Math.floor(Date.now() / 1000) + 60 * 20);
  //       const permit = {
  //         details: {
  //           token: tokenIn,
  //           amount: amountIn,
  //           expiration: Number(expiration),
  //           nonce,
  //         },
  //         spender: routerAddress,
  //         sigDeadline: expiration,
  //       };
  //       const eip712Domain = {
  //         name: 'Permit2',
  //         chainId: bsc.id,
  //         verifyingContract: permit2.address,
  //       };

  //       const signature = await walletClient.signTypedData({
  //         domain: eip712Domain,
  //         types: {
  //           PermitDetails: [
  //             { name: 'token', type: 'address' },
  //             { name: 'amount', type: 'uint160' },
  //             { name: 'expiration', type: 'uint48' },
  //             { name: 'nonce', type: 'uint48' },
  //           ],
  //           PermitSingle: [
  //             { name: 'details', type: 'PermitDetails' },
  //             { name: 'spender', type: 'address' },
  //             { name: 'sigDeadline', type: 'uint256' },
  //           ],
  //         },
  //         message: permit,
  //         primaryType: 'PermitSingle',
  //       });

  //       const PERMIT2_PERMIT_ABI = [
  //         { internalType: 'address', name: 'owner', type: 'address' },
  //         {
  //           components: [
  //             {
  //               components: [
  //                 { internalType: 'address', name: 'token', type: 'address' },
  //                 {
  //                   internalType: 'uint160',
  //                   name: 'amount',
  //                   type: 'uint160',
  //                 },
  //                 {
  //                   internalType: 'uint48',
  //                   name: 'expiration',
  //                   type: 'uint48',
  //                 },
  //                 { internalType: 'uint48', name: 'nonce', type: 'uint48' },
  //               ],
  //               internalType: 'struct IAllowanceTransfer.PermitDetails',
  //               name: 'details',
  //               type: 'tuple',
  //             },
  //             { internalType: 'address', name: 'spender', type: 'address' },
  //             {
  //               internalType: 'uint256',
  //               name: 'sigDeadline',
  //               type: 'uint256',
  //             },
  //           ],
  //           internalType: 'struct IAllowanceTransfer.PermitSingle',
  //           name: 'permitSingle',
  //           type: 'tuple',
  //         },
  //         { internalType: 'bytes', name: 'signature', type: 'bytes' },
  //       ];

  //       const V2_SWAP_EXACT_IN_COMMAND = 0x08; // Universal Router command for swap
  //       const PERMIT2_PERMIT_COMMAND = 0x0a;
  //       const commands = [PERMIT2_PERMIT_COMMAND, V2_SWAP_EXACT_IN_COMMAND].reduce(
  //         (acc, command) => acc.concat(command.toString(16).padStart(2, '0')),
  //         '0x',
  //       );
  //       console.log('commands:::', commands);

  //       // const inputs = [
  //       //   encodeAbiParameters(PERMIT2_PERMIT_ABI, [
  //       //     account,
  //       //     {
  //       //       details: {
  //       //         token: tokenIn,
  //       //         amount: amountIn,
  //       //         expiration,
  //       //         nonce,
  //       //       },
  //       //       spender: routerAddress,
  //       //       sigDeadline: expiration,
  //       //     },
  //       //     signature,
  //       //   ]),

  //       //   encodeAbiParameters(
  //       //     [
  //       //       { type: 'address', name: 'recipient' },
  //       //       { type: 'uint256', name: 'amountIn' },
  //       //       { type: 'uint256', name: 'amountOutMin' },
  //       //       { type: 'address[]', name: 'path' },
  //       //       { type: 'bool', name: 'flag' },
  //       //     ],
  //       //     [account, amountIn, amountOutMin, [tokenIn, tokenOut], true],
  //       //   ),
  //       // ];
  //       const inputs = [
  //         [account, permit, signature],
  //         [account, amountIn, amountOutMin, [tokenIn, tokenOut], true],
  //       ];

  //       const V2_SWAP_EXACT_IN_ABI = [
  //         { type: 'address', name: 'recipient' },
  //         { type: 'uint256', name: 'amountIn' },
  //         { type: 'uint256', name: 'amountOutMin' },
  //         { type: 'address[]', name: 'path' },
  //         { type: 'bool', name: 'flag' },
  //       ];

  //       const encodedInputs = [
  //         encodeAbiParameters(PERMIT2_PERMIT_ABI, inputs[0]),
  //         encodeAbiParameters(V2_SWAP_EXACT_IN_ABI, inputs[1]),
  //       ];
  //       console.log('++++', [tokenIn, tokenOut]);
  //       // const txHash = await universal.write.execute([commands as Hex, encodedInputs, expiration]);
  //       const txHash = await router.write.swapTokensForExactTokens([
  //         0n,
  //         amountIn,
  //         [tokenIn, tokenOut],
  //         account!,
  //         BigInt(Date.now() + 1000 * 60 * Number(tradingForm.deadline || 5)),
  //       ]);

  //       console.log('Swap Transaction Hash:', txHash);

  //       const receipt = await publicClient.waitForTransactionReceipt({
  //         hash: txHash,
  //         timeout: 60_000,
  //       });
  //       if (receipt.status === 'success') {
  //         console.log(`Successfully sold ${formatEther(amountIn)} ${tokenIn.toString()}`);
  //       } else {
  //         console.log(`Failed to sell ${formatEther(amountIn)} ${tokenIn.toString()}`);
  //       }
  //     }
  //   } catch (error) {
  //     toast.error('Oops, something went wrong');
  //     console.error('Failed to buy tokens, error msg:', error);
  //   } finally {
  //     setOnProcessing(false);
  //   }
  // };

  const runSellHandler = useCallback(async () => {
    if (!account || !walletClient) return;

    setOnProcessing(true);
    try {
      const approvalAmount = parseEther(
        `${+tradingForm.maxNumberOfTrades * +tradingForm.orderSize}`,
      );

      const token1 = new Token(ChainId.BSC, tokenAddress, 18, 'Kisar');
      const token2 = new Token(ChainId.BSC, usdtAddress, 18, 'USDT');
      const pair = await Fetcher.fetchPairData(token1, token2, walletClient);
      const route = new Route([pair], token1, token2);
      let amountIn = approvalAmount + 1n;
      const slippageTolerance = new Percent(Number(tradingForm.slippage) * 100, '10000'); // 50 bips, or 0.50% - Slippage tolerance

      const trade = new Trade(
        route,
        CurrencyAmount.fromRawAmount(token1, amountIn),
        TradeType.EXACT_INPUT,
      );

      const amountOutMin = trade.minimumAmountOut(slippageTolerance); // needs to be converted to e.g. hex
      const deadline = Math.floor(Date.now() / 1000) + 60 * +tradingForm.deadline;
      const erc20 = getContract({
        address: tokenAddress,
        abi: erc20Abi,
        client: { public: publicClient, wallet: walletClient! },
      });
      await erc20.write.approve([routerAddress, approvalAmount + 1n]);

      const router = getContract({
        address: routerAddress,
        abi: routerAbi,
        client: { public: publicClient, wallet: walletClient! },
      });

      for (let i = 0; i < +tradingForm.maxNumberOfTrades; i++) {
        const rawTxn = await router.write.swapExactTokensForTokensSupportingFeeOnTransferTokens([
          amountIn,
          parseEther(amountOutMin.toExact()),
          [tokenAddress, usdtAddress],
          account!,
          BigInt(deadline),
        ]);

        let reciept = await publicClient.waitForTransactionReceipt({
          hash: rawTxn!,
        });
        if (reciept.status === 'reverted') {
          console.log(` - Transaction was reverted -
              Transaction Hash: ${rawTxn}
              Navigate to https://bscscan.com/tx/${rawTxn} to see your transaction`);
        } else if (reciept.status === 'success') {
          console.log(` - Transaction is mined -
              Transaction Hash: ${rawTxn}
              Block Number: ${reciept.blockNumber}
              Navigate to https://bscscan.com/tx/${rawTxn} to see your transaction`);
        }
      }
      toast.success('All trades has been successfully executed');
    } catch (e) {
      toast.error('Oops, something went wrong');
      console.error('Failed to sell tokens, error msg:', e);
    } finally {
      setOnProcessing(false);
    }
  }, [
    tradingForm.maxNumberOfTrades,
    tradingForm.orderSize,
    tradingForm.slippage,
    tradingForm.deadline,
    tokenAddress,
    usdtAddress,
    walletClient,
    publicClient,
    routerAddress,
    account,
  ]);

  const runBuyHandler = async () => {
    // console.log('Trading form:', tradingForm);

    if (!account || !walletClient) return;

    try {
      setOnProcessing(true);
      const usdt = getContract({
        address: usdtAddress,
        abi: erc20Abi,
        client: { public: publicClient, wallet: walletClient },
      });

      const router = getContract({
        address: routerAddress,
        abi: routerAbi,
        client: { public: publicClient, wallet: walletClient },
      });

      const pair = getContract({
        address: pairAddress,
        abi: pairAbi,
        client: { public: publicClient, wallet: walletClient },
      });

      const approvalAmount = parseEther(
        `${Number(tradingForm.maxNumberOfTrades) * Number(tradingForm.orderSize)}`,
      );
      await usdt.write.approve([routerAddress, BigInt(approvalAmount)]);

      for (let i = 0; i < Number(tradingForm.maxNumberOfTrades); i++) {
        const totalSupply = await pair.read.totalSupply(); // LP token
        if (totalSupply === 0n) {
          console.log(`Pool is empty, trading is cancelled`);
          continue;
        }

        const tokenIn = usdtAddress;
        const tokenOut = tokenAddress;

        // `${Number(tradingForm.orderSize) - (Number(tradingForm.orderSize) * 25) / 10000}`,
        const amountIn = parseEther(tradingForm.orderSize);

        const amounts = await router.read.getAmountsOut([amountIn, [tokenIn, tokenOut]]);
        // console.log('amounts:::', amounts);
        // Let's define our price tollerance
        const amountOutMin =
          amounts[1] - BigInt(Number(amounts[1]) * Number(tradingForm.slippage)) / 100n;
        // console.log('amountOutMin:::', amountOutMin);

        const txHash = await router.write.swapExactTokensForTokens([
          amountIn,
          amountOutMin,
          [tokenIn, tokenOut],
          account!,
          BigInt(Date.now() + 1000 * 60 * Number(tradingForm.deadline || 5)),
        ]);
        console.log(`Transaction hash: ${txHash}`);

        const receipt = await publicClient.waitForTransactionReceipt({
          hash: txHash,
          timeout: 60_000,
        });
        if (receipt.status === 'success') {
          console.log(`Successfully bought ${formatEther(amountOutMin)} ${tokenOut.toString()}`);
        } else {
          console.error(`Failed to buy ${tokenOut.toString()}, tx hash: ${txHash}`);
        }
      }
      toast.success('All trades has been successfully executed');
    } catch (error) {
      toast.error('Oops, something went wrong');
      console.error('Failed to buy tokens, error msg:', error);
    } finally {
      setOnProcessing(false);
    }
  };

  const runHandler = async () => {
    if (tradingForm.orderType === '1') {
      await runBuyHandler();
    } else if (tradingForm.orderType === '2') {
      await runSellHandler();
    }
  };

  return (
    <>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { width: '100%' } }}
        noValidate
        autoComplete="off"
      >
        {isValidPrivateKey && (
          <Box
            sx={{ width: '100%', my: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'end' }}
          >
            <Box display={'flex'} fontSize={'14px'} alignItems={'center'}>
              {t('accountLabel')}
              <Typography ml={0.3} fontSize={'14px'} fontWeight={'bold'}>
                {account}
              </Typography>
            </Box>
          </Box>
        )}
        <FormControl required fullWidth sx={{ mb: 3 }} size="small" variant="outlined">
          <InputLabel htmlFor="private-key">{t('privateKeyLabel')}</InputLabel>
          <OutlinedInput
            id="private-key"
            name="privateKey"
            type={showPrivateKey ? 'text' : 'password'}
            value={tradingForm.privateKey}
            onChange={handleInputChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle private key visibility"
                  onClick={handleClickShowPrivateKey}
                  onMouseDown={handlePwdMouseEvent}
                  onMouseUp={handlePwdMouseEvent}
                  edge="end"
                >
                  {showPrivateKey ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="private-key-helper-text"
            placeholder={t('privateKeyPlaceholder')}
            label={t('privateKeyLabel')}
          />
          {(!tradingForm.privateKey || isValidPrivateKey) && (
            <FormHelperText id="private-key-helper-text" sx={{ ml: 0, fontWeight: 'bold' }}>
              {t('privateKeyHelperText')}
            </FormHelperText>
          )}

          {!!tradingForm.privateKey && !isValidPrivateKey && (
            <FormHelperText sx={{ ml: 0 }} error>
              {t('privateKeyErrorText')}
            </FormHelperText>
          )}
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            '& .MuiTextField-root': { width: matches ? '100%' : '30ch' },
          }}
        >
          <Grid2
            container
            spacing={{ xs: 2 }}
            sx={{ justifyContent: 'space-between' }}
            columns={{ xs: 12 }}
          >
            <TextField
              required
              id="trading-pair"
              label={t('tradingPairLabel')}
              defaultValue="Kisar / USDT"
              size="small"
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />

            <TextField
              required
              size="small"
              id="order-type"
              label={t('orderTypeLabel')}
              name="orderType"
              value={tradingForm.orderType}
              onChange={handleInputChange}
              select
            >
              <MenuItem value="1">{t('buyLabel')}</MenuItem>
              <MenuItem value="2">{t('sellLabel')}</MenuItem>
            </TextField>

            <TextField
              required
              id="max-number-of-trades"
              label={t('maxNumberOfTradesLabel')}
              name="maxNumberOfTrades"
              value={tradingForm.maxNumberOfTrades}
              onChange={handleInputChange}
              size="small"
            />

            <TextField
              required
              id="order-size"
              label={t('orderSizeLabel')}
              name="orderSize"
              value={tradingForm.orderSize}
              onChange={handleInputChange}
              slotProps={{
                input: {
                  inputComponent: NumericFormatCustom as any,
                },
              }}
              size="small"
              placeholder={t('orderSizePlaceholder')}
            />

            <FormControl
              sx={{ width: matches ? '100%' : '30ch' }}
              required
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="max-slippage">{t('slippageLabel')}</InputLabel>
              <OutlinedInput
                id="max-slippage"
                name="slippage"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                label={t('slippageLabel')}
                value={tradingForm.slippage}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl
              sx={{ width: matches ? '100%' : '30ch' }}
              required
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="tx-deadline">{t('deadlineLabel')}</InputLabel>
              <OutlinedInput
                id="tx-deadline"
                name="deadline"
                endAdornment={<InputAdornment position="end">{t('minutesLabel')}</InputAdornment>}
                label={t('deadlineLabel')}
                value={tradingForm.deadline}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid2>

          <Accordion sx={{ width: '100%' }}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="advanced-config"
              id="config-header"
            >
              <Typography>{t('advancedSettings')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  id="rpc"
                  name="rpc"
                  label="RPC"
                  value={tradingForm.rpc}
                  onChange={handleInputChange}
                  size="small"
                  fullWidth
                />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Box
            sx={{
              mt: 2,
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              alignItems: 'end',
            }}
          >
            <Button
              disableElevation
              variant="contained"
              disableRipple
              style={{ textTransform: 'capitalize' }}
              onClick={runHandler}
              disabled={!isRunnable || onProcessing}
            >
              {t('runBtnLabel')}
            </Button>
          </Box>
        </Box>
      </Box>

      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1, flexDirection: 'column' })}
        open={onProcessing}
      >
        <Box component={'div'}>
          <CircularProgress color="inherit" />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography>Please wait for a while to complete your trades</Typography>
        </Box>
      </Backdrop>
    </>
  );
}
